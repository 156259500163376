import { useSelector } from "react-redux";
import DashboardCard from "../components/DashboardCard";
import { Button, FormControl, FormLabel, Input, Toast } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import RecentActivityCard from "../components/RecentActivityCard";

const Dashboard = () => {
  const { name, isSuper } = useSelector((state) => state.auth);
  const [cookies] = useCookies();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const [isRecentActivityLoading, setIsRecentActivityLoading] = useState(false);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [totalBilties, setTotalBilties] = useState(0);
  const [totalChallans, setTotalChallans] = useState(0);
  const [totalBills, setTotalBills] = useState(0);
  const [recentBilties, setRecentBilties] = useState([]);
  const [recentChallans, setRecentChallans] = useState([]);
  const [recentBills, setRecentBills] = useState([]);

  const fetchSummaryHandler = async (e) => {
    if (e) {
      e.preventDefault();
    }

    try {
      setIsSummaryLoading(true);
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "dashboard/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${cookies?.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from: from,
            to: to,
          }),
        }
      );
      const data = await response.json();
      if (!data.success) {
        throw new Error(data.message);
      }
      setTotalEmployees(data.employees);
      setTotalBilties(data.total_bilty_count);
      setTotalBills(data.total_bill_count);
      setTotalChallans(data.total_challan_count);
    } catch (err) {
      Toast({
        status: "error",
        description: err?.message || "Something went wrong",
        position: "top",
      });
    } finally {
      setIsSummaryLoading(false);
    }
  };

  const fetchRecentActivitiesHandler = async () => {
    try {
      setIsRecentActivityLoading(true);
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "dashboard/recent",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${cookies?.token}`,
          },
        }
      );
      const data = await response.json();
      if (!data.success) {
        throw new Error(data.message);
      }
      setRecentBilties(data.bilties);
      setRecentChallans(data.challans);
      setRecentBills(data.bills);
    } catch (err) {
      Toast({
        status: "error",
        description: err?.message || "Something went wrong",
        position: "top",
      });
    } finally {
      setIsRecentActivityLoading(false);
    }
  };

  useEffect(() => {
    fetchSummaryHandler();
    fetchRecentActivitiesHandler();
  }, []);

  return (
    <div className="py-5 px-5">
      <h1 className="text-4xl mt-5 mb-6 font-bold">Hi {name}</h1>

      <div>
        <form
          onSubmit={fetchSummaryHandler}
          className="flex gap-2 flex-col lg:flex-row items-end justify-end"
        >
          <FormControl width="200px" isRequired>
            <FormLabel>From</FormLabel>
            <Input
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              type="date"
            />
          </FormControl>
          <FormControl width="200px" isRequired>
            <FormLabel>To</FormLabel>
            <Input
              value={to}
              onChange={(e) => setTo(e.target.value)}
              type="date"
            />
          </FormControl>
          <div className="flex gap-2">
            <Button type="submit" color="#ffffff" backgroundColor="#3182ce">
              Apply
            </Button>
            <Button
              onClick={() => {
                setFrom("");
                setTo("");
                fetchSummaryHandler();
              }}
              type="button"
              color="#ffffff"
              backgroundColor="#ff4949"
            >
              Reset
            </Button>
          </div>
        </form>
      </div>

      {isSummaryLoading && (
        <div className="mt-5 flex justify-center">
          <Loader />
        </div>
      )}
      {!isSummaryLoading && (
        <div className="mt-5 grid items-center justify-items-center grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2">
          {isSuper && (
            <DashboardCard title="Total Employees" content={totalEmployees} link={''} />
          )}
          <DashboardCard
            title="Total Bilties"
            content={totalBilties}
            link="/dashboard/"
          />
          <DashboardCard
            title="Total Challans"
            content={totalChallans}
            link="/dashboard/all-challan"
          />
          {isSuper && (
            <DashboardCard
              title="Total Bills"
              content={totalBills}
              link="/dashboard/all-bill"
            />
          )}
        </div>
      )}

      {isRecentActivityLoading && (
        <div className="mt-5 flex justify-center">
          <Loader />
        </div>
      )}
      {!isRecentActivityLoading && (
        <div className="mt-5">
          <div className="mt-1">
            <h1 className="text-2xl mt-5 mb-2 font-bold">
              Recent Bilty Related Activities
            </h1>
            {recentBilties.map((bilty) => (
              <RecentActivityCard
                content={`Bilty has been created/updated for consignee named ${
                  bilty.consignee_name_address.substr(0, 20) +
                  (bilty.consignee_name_address.length > 19 ? "..." : "")
                }`}
                time={bilty.updatedAt}
              />
            ))}
          </div>
          <div className="mt-3">
            <h1 className="text-2xl mt-5 mb-2 font-bold">
              Recent Challan Related Activities
            </h1>
            {recentChallans.map((challan) => (
              <RecentActivityCard
                content={`Challan has been created/updated for booking station named ${
                  challan.booking_station.substr(0, 20) +
                  (challan.booking_station.length > 19 ? "..." : "")
                }`}
                time={challan.updatedAt}
              />
            ))}
          </div>
          {isSuper && (
            <div className="mt-3">
              <h1 className="text-2xl mt-5 mb-2 font-bold">
                Recent Bills Related Activities
              </h1>
              {recentBills.map((bill) => (
                <RecentActivityCard
                  content={`Bill has been created/updated for consignee named ${
                    bill.consignee_name_address.substr(0, 20) +
                    (bill.consignee_name_address.length > 19 ? "..." : "")
                  }`}
                  time={bill.updatedAt}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
